import React from "react";

import Layout from "../components/layout/Layout";
import SalesQuestion from "../components/SalesQuestion";
import HubspotForm from "../components/HubspotForm";
import { navigate } from "gatsby";
import { pushSAEvent, pushToGTM, postFBEvent } from "../helpers";

export default ({ location }) => {
    const handleSubmit = () => {
        //postToTGM("google_ads_apply_for_partnership_form_submit", "Apply For Partnership Form Submit");
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Apply For Partnership Form Submit',
            'resource_name': 'Apply For Partnership'
        })
        if (typeof window !== "undefined"){
            if (window.fbq != null){
                window.fbq('track', 'CompleteRegistration')
            }
        }
        pushSAEvent("submit_apply_for_partnership");

        postFBEvent("Lead", "Partnership Form")

        navigate("/thanks-for-the-partnership-registration/")
    }
    return (
        <Layout title="Apply for Partnership" transparentNavigation={true} pageContentId="partnership-form-content" location={location}>
            <main id="partnership-form-content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-purple-brand-bg">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title font-bold size-100 is-transparent text-stroke-white">
                                apply for
                            </h1>
                            <h2 className="font-variable font-width-variation-870 size-100 is-white">
                                partnership
                            </h2>
                            <h6 className="size-40 is-white">Join Frends Partnership programme!</h6>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-three-fifths is-offset-one-fifth has-text-centered trial-form">
                                <HubspotForm
                                    formId="56c6f4c3-2cfc-467a-96dc-2c0175fc1b76"
                                    loading={<div>Loading...</div>}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <SalesQuestion
                    color="green"
                    email="juha@frends.com"
                    name="Juha Moisio"
                    tel="+358 40 739 1412"
                    title="Global Partners"
                />
            </main>
        </Layout>
    )
}